// Module imports
import React from 'react'
import { useTranslation } from 'react-i18next'

// local imports

// style imports
import {
  ItAllAddsUpContainer,
  ItAllAddsUpContents,
  ItAllAddsUpCardPairContainer,
  ItAllAddsUpCard,
} from 'components/styleComponents/EnterprisePage/EnterprisePageStyles'

// asset imports
import Card1Png from 'images/png/itAllAddsUpCard1.png'
import Card2Png from 'images/png/itAllAddsUpCard2.png'
import Card3Png from 'images/png/itAllAddsUpCard3.png'

const ItAllAddsUp = () => {
  const { t } = useTranslation('enterprisePage')
  return (
    <ItAllAddsUpContainer>
      <ItAllAddsUpContents>
        <h1>{t('ItAllAddsUpTitle')}</h1>
        <h3>{t('ItAllAddsUpSubtitle')}</h3>
        <ItAllAddsUpCardPairContainer>
          <ItAllAddsUpCard>
            <img
              src={Card1Png}
              alt="Student being very attentive to the class"
            />
          </ItAllAddsUpCard>
          <ItAllAddsUpCard>
            <div className="card-text-contents">
              <h3>{t('ItAllAddsUpCard1Title')}</h3>
              <p>{t('ItAllAddsUpCard1Description')}</p>
            </div>
            <p>{t('ItAllAddsUpAttribution1')}</p>
          </ItAllAddsUpCard>
        </ItAllAddsUpCardPairContainer>
        <ItAllAddsUpCardPairContainer>
          <ItAllAddsUpCard>
            <div className="card-text-contents">
              <h3>{t('ItAllAddsUpCard2Title')}</h3>
              <p>{t('ItAllAddsUpCard2Description')}</p>
            </div>
            <p>{t('ItAllAddsUpAttribution2')}</p>
          </ItAllAddsUpCard>
          <ItAllAddsUpCard>
            <img
              src={Card2Png}
              alt="Student succeeding with English at work."
            />
          </ItAllAddsUpCard>
        </ItAllAddsUpCardPairContainer>
        <ItAllAddsUpCardPairContainer>
          <ItAllAddsUpCard>
            <img
              src={Card3Png}
              alt="Graphs showing improvement in learning with Immersive Training over traditional classrooms."
            />
          </ItAllAddsUpCard>
          <ItAllAddsUpCard>
            <div className="card-text-contents">
              <h3>{t('ItAllAddsUpCard3Title')}</h3>
              <p>{t('ItAllAddsUpCard3Description')}</p>
            </div>
            <p>{t('ItAllAddsUpAttribution3')}</p>
          </ItAllAddsUpCard>
        </ItAllAddsUpCardPairContainer>
      </ItAllAddsUpContents>
    </ItAllAddsUpContainer>
  )
}

export default ItAllAddsUp
