// Module imports
import React from 'react'
import { useTranslation } from 'react-i18next'

// local imports

// style imports
import {
  OurSuiteSweetContainer,
  OurSuiteSweetContents,
  ProductsFlexContainer,
  ProductContainer,
} from 'components/styleComponents/EnterprisePage/EnterprisePageStyles'

// asset imports
import FluentWorldsProduct from 'images/png/fluentWorldsProduct.png'
import FWAProduct from 'images/png/FWAProduct.png'
import PerfectAccentProduct from 'images/png/perfectAccentProduct.png'

const OurSuiteSweet = () => {
  const { t } = useTranslation('enterprisePage')
  return (
    <OurSuiteSweetContainer>
      <OurSuiteSweetContents>
        <h1>{t('ourSuiteSweetTitle')}</h1>
        <h3>{t('ourSuiteSweetSubtitle')}</h3>
        <ProductsFlexContainer>
          <ProductContainer>
            <img
              src={FluentWorldsProduct}
              alt="FluentWorlds app open on a smart phone and a tablet"
            />

            <div className="product-description">
              <h3>{t('fluentWorldsProductTitle')}</h3>
              <p>{t('fluentWorldsProductDescription')}</p>
            </div>
          </ProductContainer>
          <ProductContainer>
            <img
              src={FWAProduct}
              alt="A group of students learning in FluentWorlds Academy as virtual avatars"
            />

            <div className="product-description">
              <h3>{t('FWAProductTitle')}</h3>
              <p>{t('FWAProductDescription')}</p>
            </div>
          </ProductContainer>
          <ProductContainer>
            <img
              src={PerfectAccentProduct}
              alt="The PerfectAccent logo showing on a tablet and a smart phone"
            />
            <div className="product-description">
              <h3>{t('perfectAccentProductTitle')}</h3>
              <p>{t('perfectAccentProductDescription')}</p>
            </div>
          </ProductContainer>
        </ProductsFlexContainer>
      </OurSuiteSweetContents>
    </OurSuiteSweetContainer>
  )
}

export default OurSuiteSweet
