// Module imports
import React from 'react'
import { useTranslation } from 'react-i18next'

// local imports

// style imports
import {
  MasteryWithMethodologyContainer,
  MasteryWithMethodologyContents,
  MasteryWithMethodologyImgContainer,
  MasteryWithMethodologyImgOverlay,
  WhitePaperButton,
} from 'components/styleComponents/EnterprisePage/EnterprisePageStyles'

// asset imports
import masteryMethodologyImage from 'images/png/masteryMethodology.png'
import whitePaperPdf from 'images/pdf/FluentWorldsWhitePaperFinal.pdf'

const MasteryWithMethodology = () => {
  const { t } = useTranslation('enterprisePage')
  return (
    <MasteryWithMethodologyContainer>
      <MasteryWithMethodologyContents>
        <h1>{t('MasteryMethodologyTitle')}</h1>
        <h3>{t('MasteryMethodologySubtitle')}</h3>
        <MasteryWithMethodologyImgContainer>
          <img
            src={masteryMethodologyImage}
            alt="A professor who is happy to be using FluentWorlds products"
          />
          <MasteryWithMethodologyImgOverlay>
            <h3>{t('MasteryMethodologyOverlayTitle')}</h3>
            <p>{t('MasteryMethodologyOverlayDescription')}</p>
            <a href={whitePaperPdf} target="_blank" rel="noreferrer">
              <WhitePaperButton>{t('ReadWhitePaperButton')}</WhitePaperButton>
            </a>
          </MasteryWithMethodologyImgOverlay>
        </MasteryWithMethodologyImgContainer>
      </MasteryWithMethodologyContents>
    </MasteryWithMethodologyContainer>
  )
}

export default MasteryWithMethodology
