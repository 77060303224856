// Module imports
import React from 'react'
import { useTranslation } from 'react-i18next'

// local imports

// style imports
import {
  OurPartnersContainer,
  OurPartnersContents,
  OurPartnersFlexContainer,
} from 'components/styleComponents/EnterprisePage/EnterprisePageStyles'

// asset imports
import SourceNextLogo from 'images/png/sourcenextLogo.png'
import UniminutoLogo from 'images/png/uniminutoLogo.png'
import TimweLogo from 'images/png/timweLogo.png'
import AquipagoLogo from 'images/png/aquipagoLogo.png'
import MsHoldingLogo from 'images/png/msHoldingLogo.png'
import NannoditLogo from 'images/png/nannoditLogo.png'
import SmartTechnologiesLogo from 'images/png/smartTechnologiesLogo.png'
import MindscapeLogo from 'images/png/mindscapeLogo.png'
import ConcordiaLogo from 'images/png/concordiaLogo.png'
import MovistarLogo from 'images/png/movistarLogo.png'
import IndosatLogo from 'images/png/indosatLogo.png'
import AlgarLogo from 'images/png/algarLogo.png'
import TelenorLogo from 'images/png/telenorLogo.png'
import EntelLogo from 'images/png/entelLogo.png'

const OurPartners = () => {
  const { t } = useTranslation('enterprisePage')
  return (
    <OurPartnersContainer>
      <OurPartnersContents>
        <h1>{t('OurPartnersTitle')}</h1>
        <OurPartnersFlexContainer>
          <img src={SourceNextLogo} alt="SourceNext Logo" />
          <img src={UniminutoLogo} alt="UniMinuto Logo" />
          <img src={TimweLogo} alt="Timwe Logo" />
          <img src={AquipagoLogo} alt="AquiPago Logo" />
          <img src={MsHoldingLogo} alt="MS Holdings Logo" />
          <img src={NannoditLogo} alt="Nannodit Logo" />
          <img src={SmartTechnologiesLogo} alt="Smart Technologies Logo" />
          <img src={MindscapeLogo} alt="Mindscape Logo" />
          <img src={ConcordiaLogo} alt="Concordia Logo" />
          <img src={MovistarLogo} alt="Movistar Logo" />
          <img src={IndosatLogo} alt="Indosat Logo" />
          <img src={AlgarLogo} alt="Algar Logo" />
          <img src={TelenorLogo} alt="Telenor Logo" />
          <img src={EntelLogo} alt="Entel Logo" />
        </OurPartnersFlexContainer>
      </OurPartnersContents>
    </OurPartnersContainer>
  )
}

export default OurPartners
