// Module imports
import React from 'react'
import { useTranslation } from 'react-i18next'

// local imports

// style imports
import {
  BusinessInTheMetaverseContainer,
  BusinessInTheMetaverseContents,
  GifOverlay,
} from 'components/styleComponents/EnterprisePage/EnterprisePageStyles'

// asset imports
import BackgroundVideo from 'videos/fwaenterprisebanner.mp4'

const BusinessEnglishVideo = () => {
  const { t } = useTranslation('enterprisePage')
  return (
    <BusinessInTheMetaverseContainer>
      <BusinessInTheMetaverseContents>
        <video autoPlay loop muted>
          <source src={BackgroundVideo} type="video/mp4" />
        </video>
        <GifOverlay>
          <h1>{t('BusinessInTheMetaverseTitle')}</h1>
          <p>{t('BusinessInTheMetaverseSubtitle')}</p>
          <br />
          <p>
            {t('BusinessInTheMetaverseContactUs')}
            <a href="mailto:sales@fluentworlds.com?subject=Request for details">
              sales@fluentworlds.com.
            </a>
          </p>
        </GifOverlay>
      </BusinessInTheMetaverseContents>
    </BusinessInTheMetaverseContainer>
  )
}

export default BusinessEnglishVideo
