// module imports
import React from 'react'
import { useTranslation } from 'react-i18next'

// local imports
import Seo from 'components/General/SEO'
import Layout from 'components/Layout'
import BusinessEnglishVideo from 'components/EnterprisesPage/BusinessEnglishVideo'
import OurSuiteSweet from 'components/EnterprisesPage/OurSuiteSweet'
import AcceleratedLearning from 'components/EnterprisesPage/AcceleratedLearning'
import CEOTestimonial from 'components/EnterprisesPage/CEOTestimonial'
import MasteryWithMethodology from 'components/EnterprisesPage/MasteryWithMethodology'
import ItAllAddsUp from 'components/EnterprisesPage/ItAllAddsUp'
import OurPartners from 'components/EnterprisesPage/OurPartners'
import TheFinalWord from 'components/EnterprisesPage/TheFinalWord'

// import style components

// asset imports

const Enterprise = () => {
  const { t } = useTranslation('enterprisePage')
  return (
    <Layout>
      <Seo title={t('seoTitle')} description={t('seoDescription')} />
      <BusinessEnglishVideo />
      <OurSuiteSweet />
      <AcceleratedLearning />
      <CEOTestimonial />
      <MasteryWithMethodology />
      <ItAllAddsUp />
      <OurPartners />
      <TheFinalWord />
    </Layout>
  )
}

export default Enterprise
