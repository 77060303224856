// Module imports
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'

// local imports

// style imports
import {
  AcceleratedLearningContainer,
  AcceleratedLearningContents,
  AcceleratedLearningFlexContainer,
  AcceleratedLearningFlexItem,
} from 'components/styleComponents/EnterprisePage/EnterprisePageStyles'

// asset imports
import AcceleratedLearningImage from 'images/png/acceleratedLearning.png'

const AcceleratedLearning = () => {
  const { t } = useTranslation('enterprisePage')
  return (
    <AcceleratedLearningContainer>
      <AcceleratedLearningContents>
        <h1>{t('acceleratedLearningHeader')}</h1>
        <AcceleratedLearningFlexContainer>
          <AcceleratedLearningFlexItem>
            <img
              src={AcceleratedLearningImage}
              alt="Collage of students all happy to be learning together with FluentWorlds products"
            />
          </AcceleratedLearningFlexItem>
          <AcceleratedLearningFlexItem>
            <div className="accelerated-learning-description">
              <h4>{t('acceleratedLearningTitle1')}</h4>
              <p>{t('acceleratedLearningDescription1')}</p>
              <h4>{t('acceleratedLearningTitle2')}</h4>
              <p>{t('acceleratedLearningDescription2')}</p>
              <h4>{t('acceleratedLearningTitle3')}</h4>
              <p>{t('acceleratedLearningDescription3')}</p>
              <h4>{t('acceleratedLearningTitle4')}</h4>
              <p>{t('acceleratedLearningDescription4')}</p>
              <div className="see-how-your-course-works">
                <Link to="/how-it-works">
                  <p>{t('acceleratedLearningSeeHowItWorks')}</p>
                </Link>
              </div>
            </div>
          </AcceleratedLearningFlexItem>
        </AcceleratedLearningFlexContainer>
      </AcceleratedLearningContents>
    </AcceleratedLearningContainer>
  )
}

export default AcceleratedLearning
