// Module imports
import React from 'react'
import { useTranslation } from 'react-i18next'

// local imports

// style imports
import {
  CEOTestimonialContainer,
  CEOTestimonialContents,
  CEOTestimonialOverlay,
} from 'components/styleComponents/EnterprisePage/EnterprisePageStyles'

// asset imports
import CEOTestimonialImage from 'images/png/ceotestimonial.png'

const CEOTestimonial = () => {
  const { t } = useTranslation('enterprisePage')
  return (
    <CEOTestimonialContainer>
      <CEOTestimonialContents>
        <img
          src={CEOTestimonialImage}
          alt="CEO who is happy to be using FluentWorlds products"
        />
        <CEOTestimonialOverlay>
          <p>"{t('CEOTestimonialDescription')}"</p>
          <p>
            <br />
            -Camila Amezquita
          </p>
        </CEOTestimonialOverlay>
      </CEOTestimonialContents>
    </CEOTestimonialContainer>
  )
}

export default CEOTestimonial
