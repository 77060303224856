// Module imports
import React from 'react'
import { useTranslation } from 'react-i18next'

// local imports

// style imports
import {
  TheFinalWordContainer,
  TheFinalWordContents,
  TheFinalWordImgContainer,
  TheFinalWordImgOverlay,
} from 'components/styleComponents/EnterprisePage/EnterprisePageStyles'

// asset imports
import TheFinalWordImage from 'images/png/theFinalWord.png'

const TheFinalWord = () => {
  const { t } = useTranslation('enterprisePage')
  return (
    <TheFinalWordContainer>
      <TheFinalWordContents>
        <h1>{t('TheFinalWordTitle')}</h1>
        <TheFinalWordImgContainer>
          <img
            src={TheFinalWordImage}
            alt="Business executive who is happy to be using FluentWorlds"
          />
          <TheFinalWordImgOverlay>
            <p>
              {t('TheFinalWordOverlayDescription')}
              <br />
              <br />
            </p>
            <p>{t('TheFinalWordOverlayAttribution')}</p>
          </TheFinalWordImgOverlay>
        </TheFinalWordImgContainer>
      </TheFinalWordContents>
    </TheFinalWordContainer>
  )
}

export default TheFinalWord
